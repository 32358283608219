
import { defineComponent, onMounted, ref } from "vue";
import { PositionBaseResponse } from "@/core/interfaces/ApiResponses";
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';
import { useRouterCustom } from '../../../../core/hooks/use-router-custom';
import { swalErrNotification } from '../../../../core/helpers/utils';

import PositionTable from "@/views/crafted/pages/positions/position-table/PositionTable.vue";
import ChucVuService from "@/core/services/ChucVu.service";
import ButtonCustom from '../../../../components/shared/buttons/ButtonCustom.vue';
import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';

export default defineComponent({
  name: "positions-index",
  components: { PositionTable, ButtonCustom, PageRowLayout },
  setup() {
    const { push } = useRouterCustom()
    const { ButtonsType, ButtonTypeColors } = useButtonCustom();
    const positions = ref<PositionBaseResponse[]>([]);

    onMounted(async () => {
      try {
        const {
          data: {
            data: { data: res },
          },
        } = await ChucVuService.list();
        positions.value = [...res];
      } catch (e) {
        swalErrNotification(e, 'Xin lỗi, có vẻ có lỗi xảy khi lấy thông tin chức vụ, mời bạn quay lại sau')
      }
    });

    const onDeleteButtonClickedHandler = async (id: number) => {
      try {
        await ChucVuService.delete(id);
        const {
          data: {
            data: { data: res },
          },
        } = await ChucVuService.list();
        positions.value = [...res];
      } catch (e) {
        swalErrNotification(e, 'Xin lỗi, có vẻ có lỗi xảy khi lấy xóa chức vụ này, mời bạn thao tác lại sau');
      }
    };

    return {
      positions, push,
      onDeleteButtonClickedHandler,
      ButtonsType, ButtonTypeColors,
    };
  },
});
