import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_custom = _resolveComponent("button-custom")!
  const _component_PositionTable = _resolveComponent("PositionTable")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!

  return (_openBlock(), _createBlock(_component_PageRowLayout, null, {
    "page-row-header": _withCtx(() => [
      _createTextVNode(" Danh sách các chức vụ ")
    ]),
    "page-row-action-button": _withCtx(() => [
      _createVNode(_component_button_custom, {
        "button-type": _ctx.ButtonsType.ADD,
        "color-type": _ctx.ButtonTypeColors.PRIMARY,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.push('/quan-ly/chuc-vu/tao-moi')))
      }, null, 8, ["button-type", "color-type"])
    ]),
    "page-row-content": _withCtx(() => [
      _createVNode(_component_PositionTable, {
        items: _ctx.positions,
        onOnDeleteButtonClick: _ctx.onDeleteButtonClickedHandler
      }, null, 8, ["items", "onOnDeleteButtonClick"])
    ]),
    _: 1
  }))
}