
import { defineComponent, PropType } from "vue";

import { PositionBaseResponse } from "@/core/interfaces/ApiResponses";

export default defineComponent({
  name: "position-table",
  emits: ["on-delete-button-click"],
  props: {
    items: {
      type: Array as PropType<Array<PositionBaseResponse>>,
    },
  },
  methods: {
    detailLink(id) {
      return `/quan-ly/chuc-vu/chi-tiet/${id}`;
    },
    updateLink(id) {
      return `/quan-ly/chuc-vu/cap-nhat/${id}`;
    },
    onDeleteButtonClickedHandler(id: number) {
      this.$emit("on-delete-button-click", id);
    },
  },
});
