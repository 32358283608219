import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_InfoFilled = _resolveComponent("InfoFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    style: {"width":"100%"},
    "empty-text": "Chưa có chức vụ nào trong đơn vị này",
    size: "small",
    "cell-style": {padding: '0', height: '15px'},
    data: _ctx.items,
    border: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "ID",
        property: "id"
      }),
      _createVNode(_component_el_table_column, {
        label: "Tên",
        property: "ten_chuc_vu"
      }),
      _createVNode(_component_el_table_column, {
        label: "Tên viết tắt",
        property: "ten_viet_tat"
      }),
      _createVNode(_component_el_table_column, null, {
        default: _withCtx((scope) => [
          _createVNode(_component_router_link, {
            class: "btn btn-info align-self-center me-2",
            to: _ctx.detailLink(scope.row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { style: {"font-size":"1.5em"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_InfoFilled)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["to"]),
          _createVNode(_component_router_link, {
            class: "btn btn-success align-self-center me-2",
            to: _ctx.updateLink(scope.row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { style: {"font-size":"1.5em"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_Edit)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["to"]),
          _createElementVNode("button", {
            class: "btn btn-danger align-self-center",
            onClick: ($event: any) => (_ctx.onDeleteButtonClickedHandler(scope.row.id))
          }, [
            _createVNode(_component_el_icon, { style: {"font-size":"1.5em"} }, {
              default: _withCtx(() => [
                _createVNode(_component_Delete)
              ]),
              _: 1
            })
          ], 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}